import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State, namespace } from 'vuex-class'
import { Shop } from '@/store/shop/types'
import * as locker from "@/store/locker.js"
import * as messageModal from "@/store/message-modal";
import * as lang from "@/store/lang";
import {setSessionStorageService, getSessionStorageService} from '@/services/storage.service'

const customer = namespace('customer');

export default class ShopBasketPage extends Vue {
	@State('shop') shop: Shop | undefined;

    @customer.Action('getBasket') getBasket: any;
    @customer.Action('removeFromBasket') removeFromBasket: any;
    @customer.Getter('basket') basket: any;
    @customer.Action('addToBasket') addToBasket: any;

    //Тип доставки
    shipping : any = {
        type : "standart",
        price : 15,
        name : ""
    };

    lang: any = lang.GetLang();

    get totalPrice() {
        return this.basket.reduce((acc : any, item : any) => acc + (item.price * item.count), 0);
    }

    ModelLink(modelName : string)
    {
        return "/"+this.lang+"/shop/"+modelName;
    }

    AddToBasket(product : any, count : number)
    {
        let _this = this;

        locker.Show();

        this.addToBasket({
            "id": product.id,
            "count": count
        }).then(function (response : any) {
            if (response.status == true) {
                _this.getBasket().then(function () {
                    locker.Hide();
                });
            } else {
                if (response.errors.length)
                    messageModal.Show("Ошибка", response.errors[0]);
            }
        });
    }

    ProductImage(product : any)
    {
        return "background-image: url('" + product.image + "')";
    }

    ChangeShipping(type : any, price : any, name : any)
    {
        this.shipping.type = type;
        this.shipping.price = price;
        this.shipping.name = name;

        setSessionStorageService('shipping_type', type);
        //setSessionStorageService('shipping_price', type);
    }

    Amount(item : any, increase : number)
	{
	    this.AddToBasket({id : item.product_id}, increase);
	}

	RemoveProduct(id : number)
    {
        let _this = this;

        locker.Show();

        this.removeFromBasket(id).then(function () {
            _this.getBasket().then(function () {
                locker.Hide();
            });
        });
    }

	mounted()
	{
        messageModal.Init();
	}
}
